<template>
  <div class="login">
    <div class="login_view">
      <div class="login_text">
        <span>登录</span>
      </div>
      <div class="userInput">
        <el-input v-model="account"
                  placeholder="请输入管理员账号"></el-input>
        <el-input v-model="password"
                  type='password'
                  placeholder="请输入登入密码"></el-input>
      </div>

      <div class="login_btn">
        <el-button @click="login">登录</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { postLogin, postUserInfo } from '@/request/api'
export default {
  name: 'Login',
  data () {
    return {
      account: '',
      password: '',
    }
  },
  methods: {
    //   -------------登录成功跳转页面-------------
    async skipPage (res) {
      let res_ = await postUserInfo({
      })
      let data = res_
      data.token = res.token
      if (res_.errno == 0) {
        localStorage.setItem('tuangyin_ck_userinfo', JSON.stringify(data))
      } else {
        return this.$message.error(res.message)
      }
      if (this.$route.query.redirect) {
        this.$router.push(this.$route.query.redirect);

      } else {
        this.$store.dispatch('cklist')
        this.$router.push('/index');
      }
    },

    //   -------------登录成功跳转页面-------------
    async login () {
      let res = await postLogin({
        postlogin: 1,
        username: this.account,
        password: this.password,
        mode: 'basic',
        rememberme: false
      })
      if (res.errno == 0 || res.errno == 5000) {
        this.$message.success(res.message)
        await localStorage.setItem('tuangyin_ck_userinfo', JSON.stringify(res))
        await this.skipPage(res)
      } else {
        this.$message.error(res.message)
      }
    }
  },

}
</script>

<style lang="scss">
.login {
  width: 100%;
  height: 100vh;
  min-width: 1000px;
  min-height: 800px;
  background: url("https://www.tuanyun99.com/img/warehouse_images/loginBG.png")
    no-repeat;
  background-size: 100% 100%;
  .login_view {
    position: absolute;
    top: 20%;
    left: 10%;
    width: 30%;
    height: 40vh;
    min-width: 400px;
    min-height: 300px;
    // background-color: pink;
    .login_text {
      font-size: 40px;
      font-weight: 700;
      span {
        padding-top: 14px;
        box-shadow: -5px -18px 0 -10px #616af4;
        color: #333;
      }
    }
    .userInput {
      width: 80%;
      .el-input__inner {
        padding-left: 30px;
        border-radius: 30px;
        border: none;
        background-color: #f6f2f9;
        margin-top: 30px;
        height: 50px;
        color: #5b60f1;
        font-weight: 700;
      }
    }
    .login_btn {
      margin-top: 50px;
      .el-button {
        width: 34%;
        border-radius: 30px;
        color: #fff;
        background-color: #5d72f4;
      }
    }
  }
}
</style>